<template>
  <div class="d-flex justify-content-between new-block">
    <div>
      <h2 class="float-start">
        {{
          $t(
            "DependencyFields.NewDependencyField",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
      </h2>
    </div>
    <div>
      <ActionButtons
        v-if="!actionButtonsHide"
        @methodSave="onSubmit('0', 'btn-save')"
        @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
        @clear="onClear()"
        :isNewRecord="true"
      />
    </div>
  </div>
  <form class="form-dependencyfield-new pe-2 ps-2" novalidate>
    <div v-if="errors.length > 0" class="alert alert-warning">
      <ul class="mb-0">
        <li v-for="error in errors" v-bind:key="error">
          {{ error }}
        </li>
      </ul>
    </div>
    <div v-if="isSuccess" class="alert alert-success mt-2 mb-0">
      {{
        $t(
          "Messages.SuccessMessage",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}
    </div>
    <ul class="nav nav-tabs mb-2 mt-0" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          v-show="!this.actionButtonsHide"
          class="nav-link active"
          id="pills-general-tab"
          @click="tabValidation"
          data-bs-toggle="pill"
          data-bs-target="#pills-general"
          type="button"
          role="tab"
          aria-selected="true"
        >
          <i class="bi bi-gear-fill"></i>
          {{
            $t(
              "DependencyFields.General",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" v-show="this.actionButtonsHide">
          <i class="bi bi-gear-fill"></i>
          {{
            $t(
              "DependencyFields.General",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          v-if="
            !String.isNullOrWhiteSpace(this.model.controllingFieldPublicId) &&
            !String.isNullOrWhiteSpace(this.model.dependentFieldPublicId)
          "
          @click="tabValidation"
          class="nav-link"
          id="pills-select-table-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-select-table"
          type="button"
          role="tab"
          aria-selected="false"
        >
          <i class="bi bi-table"></i>
          {{
            $t(
              "DependencyFields.SelectTable",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
        <button type="button" class="nav-link" v-else>
          <i class="bi bi-table"></i>
          {{
            $t(
              "DependencyFields.SelectTable",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </button>
      </li>
    </ul>
    <div class="tab-content">
      <div class="tab-pane fade active show" id="pills-general" role="tabpanel">
        <div class="row row-cols-1">
          <div class="col col-md-12">
            <label for="" class="form-label required">{{
              $t(
                "DependencyFields.ControllingField",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :selectedData="selectedControllingField"
              :data="this.controllingFieldList"
              :resetSelect="clearedForm"
              :allowEmpty="false"
              @onChange="onChangeForControllingField"
            />
          </div>
        </div>
        <div class="row row-cols-1 mt-2">
          <div class="col col-md-12">
            <label for="" class="form-label required">{{
              $t(
                "DependencyFields.DependentField",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}</label>
            <FormSelect
              type="SelectWithLocalData"
              :selectedData="selectedDependentField"
              :data="this.dependentFieldList"
              :resetSelect="clearedForm"
              :allowEmpty="false"
              @onChange="onChangeForDependentField"
            />
          </div>
        </div>
      </div>
      <div class="tab-pane fade" id="pills-select-table" role="tabpanel">
        <Grid
          ref="grid"
          :settings="gridSettings"
          :columns="gridColumns"
          :rows="controllingFieldItems"
          @onEditButtonClick="onEditButtonClick"
          @onSaveButtonClick="onSaveButtonClick"
          @onCancelButtonClick="onCancelButtonClick"
        />
        <div class="row row-cols-1 choose-panel mt-3" v-if="isItemEditing">
          <div class="row row-cols-1">
            <div class="col col-md-6 col-sm-12 listbox1">
              <h5>Choose</h5>
              <ejs-listbox
                id="listbox1"
                :dataSource="dependentFieldItems"
                ref="dependentFieldItems"
                :fields="fields"
                scope="#listbox2"
                :toolbarSettings="toolbarSettings"
              ></ejs-listbox>
            </div>
            <div class="col col-md-6 col-sm-12 listbox2">
              <h5>{{ this.controllingFieldItemName }}</h5>
              <ejs-listbox
                id="listbox2"
                ref="combinedFieldItems"
                :fields="fields"
                :dataSource="selectedDependentFieldItems"
                scope="combined-list"
              ></ejs-listbox>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ActionButtons
      v-if="!actionButtonsHide && $isMobile()"
      classes="mt-3"
      @methodSave="onSubmit('0', 'btn-save')"
      @methodSaveAndNew="onSubmit('1', 'btn-save-and-new')"
      @clear="onClear()"
      :isNewRecord="true"
    />
  </form>
</template>
<script>
import $ from "jquery";
import { ListBoxComponent } from "@syncfusion/ej2-vue-dropdowns";

export default {
  name: "CustomObjectNewDependencyFields",
  components: {
    "ejs-listbox": ListBoxComponent,
  },
  data() {
    return {
      gridSettings: {
        action: "CustomObjectNewDependencyFields",
        requestUrl: String.format(""),
        requestUrlRouteParamNames: [],
        routeRequiredParameters: [],

        isPublicIdColumnHide: true,
        allowSearchPanel: true,
        allowExcelExport: false,
        allowDragAndDrop: false,
        allowPaging: false,
        allowDeleting: false,
        autoOrderProcess: true,

        buttons: [
          {
            name: "edit",
            cssClass: "btn-warning btnEdit",
            iconClass: "bi-pencil-square",
            routeButton: false,
            emitMethodName: "onEditButtonClick",
          },
          {
            name: "save",
            cssClass: "btn-success btnSave hidden",
            iconClass: "bi-save",
            routeButton: false,
            emitMethodName: "onSaveButtonClick",
          },
          {
            name: "cancel",
            cssClass: "btn-danger btnClose hidden",
            iconClass: "bi-x",
            routeButton: false,
            emitMethodName: "onCancelButtonClick",
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "DependencyFields.ControllingFieldName",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "value",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
      ],
      fields: { text: "value", value: "key" },
      toolbarSettings: {
        items: ["moveTo", "moveFrom", "moveAllTo", "moveAllFrom"],
      },
      isItemEditing: false,
      toolbar: ["ColumnChooser", "Search"],
      model: {
        publicId: "",
        controllingFieldName: "",
        controllingFieldPublicId: "",
        dependentFieldName: "",
        dependentFieldPublicId: "",
        customObjectName: "",
        customObjectPublicId: "",
        items: [],
      },
      actionButtonsHide: false,
      controllingFieldList: [],
      dependentFieldList: [],
      controllingFieldItems: [],
      //controllingFieldItemValues: [],
      controllingFieldItemName: "",
      dependentFieldItems: [],
      orjDependentFieldItems: [],
      selectedDependentFieldItems: [],
      selectedControllingField: [],
      selectedDependentField: [],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses: ".btn-save, .btn-save-and-new, .btn-clear",
      errors: [],
      columnTabActive: false,
      isSuccess: false,
      clearedForm: false,
    };
  },
  methods: {
    onEditButtonClick(rowData, event) {
      this.isItemEditing = true;
      this.actionButtonsHide = true;
      this.selectedDependentFieldItems = [];
      this.dependentFieldItems = [];
      this.dependentFieldItems = this.orjDependentFieldItems;

      $(".choose-panel").show();
      var divsToHide = document.getElementsByClassName("btnEdit");
      for (var i = 0; i < divsToHide.length; i++) {
        divsToHide[i].style.display = "none";
      }

      var currentColumn = $(event.event.target).parents("td");
      currentColumn.find(".btnClose, .btnSave").removeClass("hidden");

      this.controllingFieldItemName = rowData.value;
      if (
        String.isNullOrWhiteSpace(this.model) ||
        this.model.items.length == 0
      ) {
        return;
      }

      var items = this.model.items,
        clickedRecordId = rowData.key;
      var clickedRecordItems = items.filter(function (f) {
        return f.key === clickedRecordId;
      });

      this.selectedDependentFieldItems = [];
      if (clickedRecordItems.length > 0) {
        clickedRecordItems.forEach((element) => {
          var splittedElValue = element.value.split(this.$systemSeparator);
          this.selectedDependentFieldItems.push({
            key: splittedElValue[0],
            value: splittedElValue[1],
          });
        });
      }

      if (this.selectedDependentFieldItems.length > 0) {
        this.selectedDependentFieldItems.forEach((item) => {
          this.dependentFieldItems = this.dependentFieldItems.filter(function (
            f
          ) {
            return f.key !== item.key;
          });
        });
      }
    },
    onSaveButtonClick(rowData, event) {
      this.isItemEditing = false;
      this.actionButtonsHide = false;

      var list = JSON.parse(
        JSON.stringify(this.$refs.combinedFieldItems.getDataList())
      );

      $(".choose-panel").hide();
      var divsToHide = document.getElementsByClassName("btnEdit");
      for (var i = 0; i < divsToHide.length; i++) {
        divsToHide[i].style.display = "";
      }

      var currentColumn = $(event.event.target).parents("td");
      currentColumn.find(".btnClose, .btnSave").addClass("hidden");

      var clickedRecordId = rowData.key;
      if (!String.isNullOrWhiteSpace(this.model.items)) {
        this.model.items = this.model.items.filter(function (f) {
          return f.key !== clickedRecordId;
        });
        list.forEach((v) => {
          this.model.items.push({
            key: clickedRecordId,
            value: v.key + "|" + v.value,
          });
        });
      } else {
        list.forEach((v) => {
          this.model.items.push({
            key: clickedRecordId,
            value: v.key + "|" + v.value,
          });
        });
      }
      this.model.items = JSON.parse(JSON.stringify(this.model.items));
    },
    onCancelButtonClick(rowData, event) {
      this.actionButtonsHide = false;
      var listSelected = JSON.parse(
        JSON.stringify(this.$refs.combinedFieldItems.getDataList())
      );
      var listDependent = JSON.parse(
        JSON.stringify(this.$refs.dependentFieldItems.getDataList())
      );
      if (listSelected.length > 0) {
        var clickedRecordId = rowData.key;
        listSelected.forEach((e) => {
          listDependent.push({
            key: e.key,
            value: e.value,
          });
        });
        listSelected = [];
        this.dependentFieldItems = listDependent;
        this.selectedDependentFieldItems = listSelected;
      }

      $(".choose-panel").hide();
      var divsToHide = document.getElementsByClassName("btnEdit");
      for (var i = 0; i < divsToHide.length; i++) {
        divsToHide[i].style.display = "";
      }

      var currentColumn = $(event.event.target).parents("td");
      currentColumn.find(".btnClose, .btnSave").addClass("hidden");
    },
    onChangeForControllingField: function (selected) {
      if (!String.isNullOrWhiteSpace(selected.key)) {
        this.getControllingFieldItems(selected.key);
        this.model.controllingFieldPublicId = selected.key;
        this.model.controllingFieldName = selected.value;
      } else {
        this.model.controllingFieldPublicId = null;
        this.model.controllingFieldName = null;
      }
    },
    onChangeForDependentField: function (selected) {
      if (!String.isNullOrWhiteSpace(selected.key)) {
        this.getDependentFieldItems(selected.key);
        this.model.dependentFieldPublicId = selected.key;
        this.model.dependentFieldName = selected.value;
      } else {
        this.model.dependentFieldPublicId = null;
        this.model.dependentFieldName = null;
      }
    },
    onChangeForControllingFieldItem: function (selected) {
      this.controllingFieldItemName = selected.value;
    },
    getControllingFields(coId) {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-SummaryDependencyControllingFields?parentId={0}",
            coId
          )
        )
        .then((response) => {
          this.controllingFieldList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getDependentFields(coId) {
      this.$prodGatewayAxios
        .get(
          String.format(
            "/Lcdp-SummaryDependencyDependentFields?parentId={0}",
            coId
          )
        )
        .then((response) => {
          this.dependentFieldList = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getControllingFieldItems(id) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-SummaryFieldItems?q=&id={0}", id))
        .then((response) => {
          this.controllingFieldItems = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    getDependentFieldItems(id) {
      this.$prodGatewayAxios
        .get(String.format("/Lcdp-SummaryFieldItems?q=&id={0}", id))
        .then((response) => {
          this.dependentFieldItems = response.data.items;
          this.orjDependentFieldItems = response.data.items;
        })
        .catch(function (error) {
          //alert(error);
        });
    },
    tabValidation($event) {
      if (this.actionButtonsHide) return;

      $(".choose-panel").hide();

      var form = $(".form-dependencyfield-new");
      form.addClass("was-validated");
      if (form[0].checkValidity()) {
        form.removeClass("was-validated");
        this.columnTabActive = $event.target.id.includes("column");
      }
    },
    onClear() {
      $("#pills-general-tab").trigger("click");
      this.model = {
        publicId: "",
        controllingFieldName: "",
        //controllingFieldPublicId: "",
        dependentFieldName: "",
        //dependentFieldPublicId: "",
        customObjectName: "",
        customObjectPublicId: this.$route.params.customObjectId,
        items: [],
      };
      this.selectedDependentFieldItems = [];
      this.dependentFieldItems = [];
      this.dependentFieldItems = this.orjDependentFieldItems;
      this.clearedForm = !this.clearedForm;
      $(".form-dependencyfield-new").removeClass("was-validated");
    },
    onSubmit(submitType, buttonName) {
      var form = $(".form-dependencyfield-new");
      form.addClass("was-validated");
      if (
        !form[0].checkValidity() ||
        form.find(".ms-invalid:visible").length > 0
      ) {
        return;
      }

      if (this.model.items.length == 0) {
        $("#pills-select-table-tab").trigger("click");
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
        this.errors.push(
          this.$t(
            "DependencyFields.PleaseRelateTheFieldsWithEachOther",
            {},
            { locale: this.$store.state.activeLang }
          )
        );
        setTimeout(() => (this.errors = []), 3000);
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.errors = [];
      this.$prodGatewayAxios
        .post("/Lcdp-DependencyWizard", { ...this.model })
        .then((r) => {
          var response = r.data;
          if (response.isOk) {
            if (submitType == 0) {
              this.$router.push({
                name: "CustomObjectDetailDependencyField",
                params: {
                  customObjectId: this.$route.params.customObjectId,
                  dependencyFieldId: response.publicId,
                },
              });
            } else {
              this.isSuccess = true;
              setTimeout(
                () => (this.isSuccess = false),
                this.$appSaveAndNewMessageDuration
              );
              this.onClear();
              firstSpan.show();
              loadingBlock.hide();
              disabledButtons.prop("disabled", false);
              form.removeClass("was-validated");
            }
          } else {
            firstSpan.show();
            loadingBlock.hide();
            disabledButtons.prop("disabled", false);
            this.errors.push(response.msg);
          }
        })
        .catch((r) => {
          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);
        });
    },
  },
  watch: {
    "$route.params.customObjectId"() {
      this.model.customObjectPublicId = this.$route.params.customObjectId;
    },
  },
  mounted() {
    this.getControllingFields(this.$route.params.customObjectId);
    this.getDependentFields(this.$route.params.customObjectId);
    this.model.customObjectPublicId = this.$route.params.customObjectId;
  },
};
</script>
